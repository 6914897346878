/* eslint-disable import/prefer-default-export */
export function imgSizes(sizes) {
  /**
   * @param {array} sizes - array of arrays, each containging max-width (string) and size (string)
   */

  // helper function to build the media query based srcset for Next/Image
  // see documentation here: https://nextjs.org/docs/pages/api-reference/components/image#sizes

  const breakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1500,
  };

  const sizeStrings = sizes.map((size) =>
    size.length > 1
      ? `(max-width: ${breakpoints[size[0]]}px) ${size[1]}`
      : size[0]
  );

  return sizeStrings.join(", ");
}
