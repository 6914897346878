/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import cn from "classnames";
import { imgSizes } from "helpers/imgSizes";
import Image from "next/image";
import { formatURL, getBase64 } from "helpers/cloudfrontURLFormatting";
import styles from "components/shared/adventures/PhotoCloudfront.module.scss";

/**
 * @param {string} sizes - Pass in the media query string to feed to Next/Image. See example: https://nextjs.org/docs/api-reference/next/image
 * @param {string} sizeTemplate - "half": 50vw on large screens, 100vw below. | "full": 100vw on all screen sizes.
 */

export default function PhotoCloudfront({
  src,
  alt = "",
  priority = false,
  placeholder = "blur",
  rounded = false,
  className = null,
  sizes = null,
  sizeTemplate = null,
  onLoadingComplete = null,
  fill = false,
  width = undefined,
  height = undefined,
  fit = "cover",
}) {
  const [lqip, setLqip] = useState(null);

  function greatestCommonDenominator(a, b) {
    let c = Math.abs(a);
    let d = Math.abs(b);
    if (d > c) {
      const temp = c;
      c = d;
      d = temp;
    }
    while (true) { // eslint-disable-line no-constant-condition
      if (d === 0) return c;
      c %= d;
      if (c === 0) return d;
      d %= c;
    }
  }

  useEffect(() => {
    let lqipWidth = 10;
    let lqipHeight = 10;
    if (width !== undefined && height !== undefined) {
      const gcd = greatestCommonDenominator(width, height);
      lqipWidth = width / gcd;
      lqipHeight = height / gcd;
    }
    const formattedLqipUrl = formatURL(src, {
      resize: {
        width: lqipWidth,
        height: lqipHeight,
      },
    });
    getBase64(formattedLqipUrl).then((response) => {
      setLqip(response);
    });
  }, [src, height, width]);

  let sizeString = Array.isArray(sizes) ? imgSizes(sizes) : sizes;

  if (sizeTemplate === "full") {
    sizeString = `100vw`;
  }

  if (sizeTemplate === "half") {
    sizeString = `(max-width: ${styles.solBreakpointLg}px) 100vw, (max-width: ${styles.solBreakpointXl}px) 50vw, 100vw`;
  }

  const formattedSrc = formatURL(src);

  return (
    <>
      {lqip && (
        <Image
          fill={fill}
          src={formattedSrc}
          alt={alt}
          priority={priority}
          placeholder={placeholder}
          className={cn(
            styles.component,
            rounded && styles.rounded,
            fit && styles[`${fit}`],
            className
          )}
          sizes={sizeString || undefined}
          onLoadingComplete={onLoadingComplete}
          width={!fill && width}
          height={!fill && height}
          blurDataURL={placeholder === "blur" && lqip}
        />
      )}
    </>
  );
}

PhotoCloudfront.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  priority: PropTypes.bool,
  placeholder: PropTypes.string,
  rounded: PropTypes.bool,
  className: PropTypes.node,
  sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sizeTemplate: PropTypes.string,
  onLoadingComplete: PropTypes.func,
  fill: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  fit: PropTypes.string,
};
